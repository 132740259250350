import {ObjectId} from "mongodb";
import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {Lead} from "../../../../server/src/db/classes/lead";

@Injectable({
  providedIn: "root"
})
export class LeadsService {
  statuses = [
    {value: "answered", text: "Answered"},
    {value: "followUp", text: "Follow Up"},
    {value: "autoFollowUp", text: "Bot Follow Up"},
    {value: "noAnswer", text: "No Answer"},
    {value: "meeting", text: "Meeting"},
    {value: "done", text: "Done"}
  ];

  constructor(private connectionService: ConnectionService) {}

  async get(body) {
    try {
      return await this.connectionService.post("leads/get", body);
    } catch (e) {
      return [];
    }
  }

  async update(lead: Partial<Lead>) {
    return await this.connectionService.post("leads/update", lead);
  }

  async sendLeadMessage(leadId: ObjectId, message: string) {
    return await this.connectionService.post("leads/sendLeadMessage", {
      leadId,
      message
    });
  }

  async addMemo(leadId: ObjectId, memo: string) {
    return await this.connectionService.post("leads/addMemo", {leadId, memo});
  }

  public async removeMemoMessage(leadId: ObjectId, messageIndex: number): Promise<void> {
    return await this.connectionService.post("leads/removeMemoMessage", {
      leadId,
      messageIndex
    });
  }

  markRead = async (lead: Lead) => {
    return await this.connectionService.post("leads/markRead", {
      leadId: lead._id
    });
  };

  fetchNewMessages = async (lead: Lead) => {
    return await this.connectionService.post("leads/fetchNewMessages", {
      leadId: lead._id
    });
  };

  refreshExpiredImageMessages = async (location_id: string, lead_id: string): Promise<Lead> => {
    return await this.connectionService.post(
      "leads/refreshLeadExpiredImageMessages",
      {
        location_id,
        lead_id
      },
      "post",
      true
    );
  };
}
