import {AngularFirestore} from "@angular/fire/compat/firestore";
import {skip, Subject, Subscription} from "rxjs";
import {tap} from "rxjs/operators";

export class FirestoreDocumentListener {
  public internalUpdates$ = new Subject<{
    id: string;
    lastUpdate?: any;
    newCustomerMessages: boolean;
  }>();
  updates$ = this.internalUpdates$.asObservable();

  private subscriptions: {[key: string]: Subscription} = {};

  private bufferedUpdates: any[] = [];
  private _isReady: boolean = false;

  constructor(
    private firestoreDB: AngularFirestore,
    private collectionName: string
  ) {}

  setReady(isReady: boolean) {
    this._isReady = isReady;
    if (this._isReady && this.bufferedUpdates.length) {
      this.bufferedUpdates.forEach(update =>
        this.internalUpdates$.next(update)
      );
      this.bufferedUpdates = [];
    }
  }

  setIds(ids: string[]) {
    if (ids.length > 3) {
      this.setIds([]);
      const docRef = this.firestoreDB.collection(this.collectionName, ref =>
        ref.orderBy("lastUpdate", "desc").limit(1)
      );

      this.subscriptions["all"] = docRef
        .valueChanges()
        .pipe(skip(1))
        .subscribe((data: any[]) => {
          if (data?.[0]?.id && ids.includes(data?.[0]?.id)) {
            this.newEvent(data[0]);
          }
        });
    } else {
      ids.forEach(id => !this.subscriptions[id] && this.subscribeToId(id));
      Object.keys(this.subscriptions).forEach(
        id => !ids.includes(id) && this.unsubscribeFromId(id)
      );
    }
  }

  private subscribeToId(id: string) {
    const docRef = this.firestoreDB.collection(this.collectionName).doc(id);
    this.subscriptions[id] = docRef
      .valueChanges()
      .pipe(skip(1))
      .pipe(
        tap(update => {
          this.newEvent(update);
        })
      )
      .subscribe();
  }

  private unsubscribeFromId(id: string) {
    this.subscriptions[id].unsubscribe();
    delete this.subscriptions[id];
  }

  destroy() {
    this.setIds([]);
    this.internalUpdates$.complete();
  }

  private newEvent = (update: any) => {
    if (this._isReady) {
      this.internalUpdates$.next(update);
    } else {
      this.bufferedUpdates.push(update);
    }
  };
}
