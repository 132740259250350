import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import Encrypt from "../../../../server/src/helpers/encrypt";

@Injectable({
  providedIn: "root"
})
export class SourceService {
  constructor(private connectionService: ConnectionService) {}

  async getInvite(businessId) {
    return await this.connectionService.post("yelpLocations/invite", {
      businessId
    });
  }

  async getInvites(businessId: string): Promise<{yelpState: string; facebookAuthUrl: string; googleAuthUrl: string}> {
    return await this.connectionService.post("sources/invite", {
      businessId
    });
  }

  async getGoogleInviteUrl(businessId: string): Promise<{authUrl: string}> {
    return await this.connectionService.post("google/invite", {
      businessId
    });
  }

  async getWhatsapp(sourceId: string) {
    return await this.connectionService.post("yelpLocations/whatsapp", {
      sourceId
    });
  }

  async update(sourceId: any) {
    return await this.connectionService.post("yelpLocations/update", {
      sourceId
    });
  }

  async refreshLocationWithToken(yelpToken) {
    return await this.connectionService.post("yelpLocations/refreshWithToken", {
      yelpToken
    });
  }
}
