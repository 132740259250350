<h3>Whatsapp Chat</h3>

<div
  class="flexItemBiggest centered-row"
  *ngIf="!integration.whatsappChatsCode && integration.whatsappChatId$">
  <mat-icon class="enabled">checkmark</mat-icon>
  <div class="icon-content">Whatsapp Connected</div>
</div>
<button
  class="flexItem"
  (click)="addWhatsapp()"
  mat-button
  mat-raised-button
  *ngIf="!integration.whatsappChatsCode && integration.whatsappChatId$">
  Change to a different whatsapp chat
</button>

<div class="mat-mdc-dialog-actions">
  <button
    (click)="addWhatsapp()"
    mat-button
    mat-raised-button
    color="primary"
    *ngIf="!integration.whatsappChatsCode && !integration.whatsappChatId$">
    {{ "Generate whatsapp invite link" }}
  </button>

  <button
    mat-button
    mat-raised-button
    *ngIf="integration.whatsappChatsCode"
    [cdkCopyToClipboard]="integration.whatsappChatsCode">
    Copy
  </button>

  <a
    [href]="'whatsapp://send?text=' + encodeURIComponent(integration.whatsappChatsCode)"
    *ngIf="integration.whatsappChatsCode"
    mat-raised-button
    mat-button
    cdkFocusInitial
    >Share to Whatsapp
  </a>

  <div *ngIf="integration.whatsappChatsCode">
    <div class="bot-note">
      make sure you add our bot to the group chat before pasting the code: +1
      (213) 219-8493
    </div>
    <mat-form-field>
      <mat-label
        >Paste this code in the Whatsapp chat that you wish to
        connect</mat-label
      >
      <textarea
        cdkTextareaAutosize
        matInput
        [ngModel]="integration.whatsappChatsCode"
        [readonly]="true"></textarea>
    </mat-form-field>
  </div>
</div>
