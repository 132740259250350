<div class="flex-2 main-content">
  <div class="main-content-container">
    <div class="header">
      <h1>
        <span>Active Sources:</span>
        <span class="source-details">
          <span>(</span>
          <span title="Error" *ngIf="sessionService.session.role === 'superAdmin' && errorSourcesCount > 0">
            <span class="error">E:{{ errorSourcesCount }}</span>
            <span>/</span>
          </span>
          <span title="Payment Issue" *ngIf="paymentWarnSourcesCount > 0">
            <span class="paused">{{ paymentWarnSourcesCount }}</span>
            <span>/</span>
          </span>
          <span title="Payment Issue - Paused" *ngIf="paymentPausedSourcesCount > 0">
            <span class="paused">
              <b
                ><u>{{ paymentPausedSourcesCount }}</u></b
              >
            </span>
            <span>/</span>
          </span>
          <span title="Working" class="enabled"> {{ enabledSourcesCount - paymentWarnSourcesCount }} </span>
          <span>/</span>
          <span title="Total" class="all">{{ sources.length }})</span>
        </span>
      </h1>
      <button (click)="addYelpLocation()" mat-mini-fab color="primary">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <mat-form-field *ngIf="sources.length > maxSourcesWithoutFilter">
      <mat-label>Search</mat-label>
      <input matInput [ngModel]="filterLocationsText" (ngModelChange)="filterLocationsChanged($event || '')" />
    </mat-form-field>
    <div *ngFor="let source of filteredSources">
      <mat-card>
        <mat-card-header>
          <mat-card-title [class.enabled]="source.enabled" [class.redColor]="source.enabled && source.billingPaused">
            {{ source.name }}
            <span
              *ngIf="!source.enabled"
              style="cursor: pointer; text-decoration: underline"
              class="redColor"
              (click)="
                businessHelperService.sourceHasBilling(source) ? editLocation(source) : activateLocation(source)
              ">
              (Not Active)
            </span>
          </mat-card-title>

          <mat-card-subtitle
            *ngIf="source.enabled && (source.billingPaused || source.billingWarn)"
            class="redColor link"
            (click)="billing()">
            <span *ngIf="source.billingPaused">Paused:</span>
            Payment Issue
          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="sessionService.session.role === 'superAdmin'">
            Token Assigned: {{ source.yelpTokenId$ }}
          </mat-card-subtitle>

          <mat-card-subtitle
            *ngIf="(dataService.allTokensHashedById$ | async)[source.yelpTokenId$.toString()] as token">
            Token Name: {{ token.token_info?.first_name }} {{ token.token_info?.last_name }}
          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="sessionService.session.role === 'superAdmin'">
            Location ID: {{ source.locationId }}
          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="source.allowYelpTokenTransfer">
            <mat-icon>warning</mat-icon>
            <div class="icon-content">
              The source will be transferred during the next source-token refresh that includes it.
            </div>
          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="source.error && sessionService.session.role === 'superAdmin'" class="red">
            Error: {{ source.error }}
          </mat-card-subtitle>

          <mat-card-subtitle *ngIf="allBusinessesFlatById[source.businessId$.toString()]?.name">
            <mat-icon>location_city</mat-icon>
            <div class="icon-content">{{ allBusinessesFlatById[source.businessId$.toString()]?.name }}</div>
          </mat-card-subtitle>

          <mat-card-subtitle style="color: #8c8c8c80">
            Address: {{ source.yelpLocationInfo?.location?.display_address }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button
            mat-button
            (click)="editLocationAccounts(source)"
            *ngIf="source.enabled || source.accounts.length > 0">
            <mat-icon>group</mat-icon>
            Permissions
            <span *ngIf="source.accounts.length > 0"> ({{ source.accounts.length }}) </span>
          </button>

          <button mat-button (click)="editLocationSettings(source._id.toString(), source.name)">
            <mat-icon>forum</mat-icon>
            Messaging Settings
          </button>

          <div *ngIf="{hasBilling: businessHelperService.sourceHasBilling(source)} as billingForSource">
            <button
              color="primary"
              *ngIf="billingForSource.hasBilling"
              mat-button
              mat-raised-button
              (click)="editLocation(source)">
              Edit
            </button>

            <button
              *ngIf="!billingForSource.hasBilling"
              class="activateTrialButton"
              mat-button
              mat-raised-button
              (click)="activateLocation(source)">
              Activate
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
      <br />
    </div>
  </div>
  <mat-progress-bar *ngIf="!ready" mode="indeterminate"></mat-progress-bar>
</div>
<router-outlet></router-outlet>
