import {Pipe, PipeTransform} from "@angular/core";
import {BotMessage} from "../../../../server/src/db/classes/lead";

@Pipe({
  name: "lastTextMessage"
})
export class LastTextMessagePipe implements PipeTransform {
  transform(messages: BotMessage[], ...args: unknown[]): BotMessage | any {
    if (!messages) {
      return;
    }
    return [...messages]
      .filter(x => x)
      .reverse()
      .find(message => {
        return message.event_type === "TEXT";
      });
  }
}
