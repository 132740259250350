import {Component, HostListener, Renderer2, ViewChild} from "@angular/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {SessionService} from "../../Services/session.service";
import {LoginService} from "../../Services/login.service";
import {MatSidenav} from "@angular/material/sidenav";
import yelp from "!!raw-loader!../../../assets/yelp.svg";
import superAdmin from "!!raw-loader!../../../assets/superAdmin.svg";
import {StorageService} from "../../Services/storage.service";
import {BusinessPickerDialogComponent} from "../../dialogs/business-picker-dialog/business-picker-dialog.component";
import {UntilDestroy} from "@ngneat/until-destroy";
import {NzDialogService} from "../../utils/services/nz-dialog.service";
import {DataService} from "../../Services/data.service";
import {BillingService} from "../../Services/billing.service";
import {BusinessService} from "../../Services/business.service";
import {ContactUsDialogComponent} from "../../marketing/pages/contact-us-dialog/contact-us-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent {
  readonly MENU_CONFIG_KEY = "menuConfig";
  menuConfig = StorageService.getItem(this.MENU_CONFIG_KEY) || {isOpen: true};
  mobileQuery = false;
  smallMobileQuery = false;
  private largeQuery = false;

  @ViewChild(MatSidenav, {static: true}) sidenav: MatSidenav;
  private businesses;

  constructor(
    public dataService: DataService,
    public businessService: BusinessService,
    private loginService: LoginService,
    private billingService: BillingService,
    public sessionService: SessionService,
    private nzDialogService: NzDialogService,
    private renderer: Renderer2,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      "yelp",
      sanitizer.bypassSecurityTrustHtml(yelp)
    );
    iconRegistry.addSvgIconLiteral(
      "superAdmin",
      sanitizer.bypassSecurityTrustHtml(superAdmin)
    );
    this.onResize();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, "mobileQuery");
    this.renderer.removeClass(document.body, "smallMobileQuery");
  }

  ngAfterViewInit() {
    this.reopenMenu();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.largeQuery = window.innerWidth > 1600;

    this.mobileQuery = window.innerWidth <= 1366;
    this.renderer.removeClass(document.body, "mobileQuery");
    this.renderer.removeClass(document.body, "smallMobileQuery");
    if (this.mobileQuery) {
      this.renderer.addClass(document.body, "mobileQuery");
    }

    this.smallMobileQuery = window.innerWidth <= 820;
    if (this.smallMobileQuery) {
      this.renderer.addClass(document.body, "smallMobileQuery");
    }

    if (!this.largeQuery) {
      this.sidenav?.close();
    } else {
      this.reopenMenu();
    }
  }

  logout() {
    this.loginService.logout();
  }

  sidenavToggle(sidenav: MatSidenav) {
    this.menuConfig.isOpen = !sidenav.opened;
    StorageService.setItem(this.MENU_CONFIG_KEY, this.menuConfig);
    sidenav.toggle();
  }

  private reopenMenu() {
    if (this.menuConfig.isOpen) {
      this.largeQuery && this.sidenav?.open();
    } else if (this.sidenav?.opened) {
      this.sidenav?.close();
    }
  }

  contactUsClicked = () => {
    this.nzDialogService.open(ContactUsDialogComponent, {
      maxWidth: 600
    });
  };

  openBusinessDialog() {
    this.nzDialogService.open(BusinessPickerDialogComponent, {
      maxWidth: "800px",
      backdropClass: "CLASSA",
      panelClass: "CLASSB"
    });
  }

  protected readonly name = name;

  async openBilling() {
    await this.billingService.open();
  }
}
