import {AfterViewInit, Component, Input} from "@angular/core";
import {
  YelpConfigFollowUp,
  YelpConfigFollowUpPredefinedMessages,
  YelpConfigFollowUpPredefinedMessagesType
} from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import {OperationConfigBaseComponent} from "../operation-config-base.component";
import {OperationConfigComponent} from "../operation-config.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {DAY, HOUR, MINUTE} from "../../../../../../server/src/helpers/time-helper";

@Component({
  selector: "app-operation-config-follow-ups",
  templateUrl: "./operation-config-follow-ups.component.html",
  styleUrl: "./operation-config-follow-ups.component.scss"
})
export class OperationConfigFollowUpsComponent
  extends OperationConfigBaseComponent<YelpConfigFollowUp>
  implements AfterViewInit
{
  @Input() override inputConfig: YelpConfigFollowUp | null;
  @Input() override inputGlobalConfig: YelpConfigFollowUp;

  public yelpConfigFollowUpPredefinedMessagesType = YelpConfigFollowUpPredefinedMessagesType;
  public timeUnitTypes = Object.values(YelpConfigFollowUpPredefinedMessagesType);

  constructor(public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  ngAfterViewInit() {
    this.config.predefinedMessages.forEach(message => {
      message.type = message.type || YelpConfigFollowUpPredefinedMessagesType.Minutes;
      message.displayValue = this.getDisplayValue(message);
    });

    this.globalConfig?.predefinedMessages.forEach(message => {
      message.type = message.type || YelpConfigFollowUpPredefinedMessagesType.Minutes;
      message.displayValue = this.getDisplayValue(message);
    });
  }

  protected override notifyConfigChange(): void {
    this.operationConfigComponent.followUpsConfigChaged(this.config, this.isGlobalConfigApplied);
  }

  public addMessage(): void {
    const message: YelpConfigFollowUpPredefinedMessages = {
      text: "",
      minutes: 60,
      displayValue: 60,
      type: YelpConfigFollowUpPredefinedMessagesType.Minutes
    };
    this.config.predefinedMessages.push(message);
  }

  public removeMessage(message: YelpConfigFollowUpPredefinedMessages) {
    const idx = this.config.predefinedMessages.indexOf(message);
    this.config.predefinedMessages.splice(idx, 1);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.config.predefinedMessages, event.previousIndex, event.currentIndex);
  }

  public updateMinutes(message: YelpConfigFollowUpPredefinedMessages): void {
    const conversionFactors = {
      [YelpConfigFollowUpPredefinedMessagesType.Minutes]: MINUTE / MINUTE,
      [YelpConfigFollowUpPredefinedMessagesType.Hours]: HOUR / MINUTE,
      [YelpConfigFollowUpPredefinedMessagesType.Days]: DAY / MINUTE
    };

    message.minutes = Math.round(message.displayValue * conversionFactors[message.type]);
    this.notifyConfigChange();
  }

  private getDisplayValue(message: YelpConfigFollowUpPredefinedMessages): number {
    const conversionFactors = {
      [YelpConfigFollowUpPredefinedMessagesType.Minutes]: MINUTE / MINUTE,
      [YelpConfigFollowUpPredefinedMessagesType.Hours]: MINUTE / HOUR,
      [YelpConfigFollowUpPredefinedMessagesType.Days]: MINUTE / DAY
    };

    return message.minutes * conversionFactors[message.type];
  }
}
