import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "objectHasKeys"
})
export class ObjectHasKeysPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return Object.keys(value).length > 0;
  }
}
