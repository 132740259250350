import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app/app.module";
import {DateTime, Duration, Interval} from "luxon";
(window as any).DateTime = DateTime;
(window as any).Interval = Interval;
(window as any).Duration = Duration;

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
