<h1 class="header">{{ forgot ? "Reset password" : "Sign Up" }}</h1>
<form>
  <div class="content">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput autoFocus [(ngModel)]="email" (ngModelChange)="emailChanged($event)" name="email" />
      <mat-hint *ngIf="errors?.email">{{ errors.email }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input [type]="showPassword ? 'text' : 'password'" matInput [(ngModel)]="pass" name="password" />
      <mat-hint *ngIf="errors?.pass">{{ errors.pass }}</mat-hint>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="showPassword" name="show">Show Password</mat-checkbox>
  </div>

  <div>
    <button type="submit" mat-button mat-raised-button color="primary" class="login-button" (click)="this.signUp()">
      {{ forgot ? "Reset password" : "Sign Up" }}
    </button>
    <button type="submit" mat-button mat-raised-button class="login-button" (click)="this.gotoLogin()">
      Back to Login
    </button>
  </div>
</form>
