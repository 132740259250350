<mat-form-field class="example-full-width" floatLabel="always" (click)="$event.stopPropagation()">
  <mat-label>Source Name</mat-label>
  <input
    type="text"
    matInput
    [placeholder]="_selectedSources?.name"
    [formControl]="myControl"
    [matAutocomplete]="auto" />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    name="_selectedSources"
    ngDefaultControl
    [ngModel]="_selectedSources"
    (optionSelected)="souceSelected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
