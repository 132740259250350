<ng-template #login>
  <div class="home-buttons">
    <a class="home-register button" href="/login">Get Started</a>
    <asl-google-signin-button
      type="standard"
      style="padding-left: 30px"
      text="continue_with"
      size="large"
      shape="pill"
      theme="outline"></asl-google-signin-button>
  </div>
</ng-template>

<app-google-popup-login></app-google-popup-login>

<!--<a class="floating-icon whatsapp" href="https://wa.link/f0z3in" target="_blank">-->
<!--  <img src="assets/WhatsApp.svg" alt="contact us on whatsapp" />-->
<!--</a>-->
<a class="floating-icon call-now" (click)="callNow($event)" href="tel:+1 (213) 693-1377" target="_blank">
  <img src="assets/call-now.svg" alt="Call Now" />
</a>

<ng-template #menu>
  <div class="home-buttons" style="user-select: none">
    <a href="#home" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Home</a>
    <a href="#about" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Details</a>
    <a href="#pricing" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false">Pricing</a>
    <a href="#testimonials" (click)="menuIsOpen = false; (true)" class="home-nav-link" [attr.draggable]="false"
      >Customers</a
    >
  </div>
</ng-template>

<div class="home-container" [class.midPageScroll]="!scrolledTop" [class.mobileQuery]="mobileQuery">
  <div class="home-header">
    <header class="home-navbar-interactive">
      <span class="home-logo">
        <a href="#home">
          <dynamic-logo [fontWeight]="700" />
        </a>
      </span>
      <div class="home-desktop-menu">
        <nav class="home-links">
          <ng-template [ngTemplateOutlet]="menu"></ng-template>
        </nav>
        <ng-template [ngTemplateOutlet]="login"></ng-template>
      </div>
      <div class="home-burger-menu" (click)="menuIsOpen = !menuIsOpen">
        <asl-google-signin-button
          type="icon"
          style="padding-right: 30px"
          text="continue_with"
          size="medium"
          shape="circle"
          theme="outline"></asl-google-signin-button>
        <div class="home-close-menu">
          <svg viewBox="0 0 1024 1024" class="home-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
      </div>
      <div class="home-mobile-menu" [class.menuIsOpen]="menuIsOpen">
        <div class="home-nav">
          <div class="home-top">
            <span class="home-logo">
              <dynamic-logo />
            </span>

            <div class="home-close-menu" (click)="menuIsOpen = !menuIsOpen">
              <asl-google-signin-button
                type="icon"
                style="padding-right: 30px"
                text="continue_with"
                size="medium"
                shape="circle"
                theme="outline"></asl-google-signin-button>
              <svg viewBox="0 0 1024 1024" class="home-icon02">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <nav class="home-links1">
            <ng-template [ngTemplateOutlet]="menu"></ng-template>
          </nav>
          <ng-template [ngTemplateOutlet]="login"></ng-template>
        </div>
      </div>
    </header>
  </div>
  <div class="home-hero" id="home">
    <div class="home-hero1 anchor">
      <div class="home-container01">
        <div class="home-details1">
          <div class="home-container02">
            <div class="title-container">
              <h1 class="home-hero-heading heading1">Auto Responder and Follow-ups for Leads!</h1>
              <h2 class="home-details-sub-heading">Reduce response time and close more deals effortlessly.</h2>
            </div>
            <div class="main-buttons">
              <div class="home-btn-group">
                <a class="home-hero-button button" href="/login">Start for free</a>
              </div>
              <div class="home-btn-group">
                <a class="home-hero-button button2" (click)="contactUs()">Contact Us</a>
              </div>
            </div>
          </div>
          <img alt="yelp bot" src="assets/bot9.webp" class="home-details-image" [attr.draggable]="false" />
        </div>
      </div>
    </div>
  </div>
  <div class="home-details anchor" id="anchor-about">
    <div class="home-details2">
      <div class="home-container02">
        <h2 class="home-details-heading heading2">How does it work?</h2>
        <span class="home-details-sub-heading">
          When a new lead arrives at your business, our bot quickly sends a welcome message and follows up to get their
          contact info. This gives you an edge, securing deals faster than the competition!
        </span>
      </div>
    </div>
  </div>
  <div class="home-details anchor" id="about"></div>
  <div class="home-features">
    <div class="home-features-container anchor" id="features">
      <div class="home-features1">
        <div class="home-container04">
          <feature-card
            Heading="Optimize Every Engagement"
            SubHeading="Customize your welcome messages. Schedule follow-ups and more."
            width="450"
            paddingTopImage="50"
            OriginalImage="assets/main/config_new.gif"
            Image="assets/main/config_new.gif"></feature-card>
          <feature-card
            Heading="Performance Dashboard - Overview & Analysis"
            OriginalImage="assets/main/original/pc2.png"
            Image="assets/main/pc2.webp"
            paddingTopImage="50"
            SubHeading="Get a detailed analysis and performance overview. Split the data by dates and other parameters to get custom insights."></feature-card>
          <feature-card
            Heading="Lead Control Center"
            OriginalImage="assets/main/original/pc3_noPad.png"
            Image="assets/main/pc3.webp"
            SubHeading="Use our Bot to simplify how you manage and talk to your leads through Yelp. Use our all-in-one lead management solution."></feature-card>
          <feature-card
            Heading="Freedom to Work, Anytime, Anywhere"
            SubHeading="The system is Mobile Friendly! Use your Phone to stay connected. Work smarter on the go."
            width="200"
            OriginalImage="assets/main/original/phone.png"
            Image="assets/main/phone.webp"></feature-card>
        </div>
      </div>
    </div>
  </div>
  <div class="home-pricing">
    <div class="home-pricing1 anchor" id="pricing">
      <div class="home-container05">
        <h2 class="home-pricing-heading heading2">Pricing</h2>
        <span class="home-pricing-sub-heading">
          <div class="cards">
            <img src="assets/payments/stripe.png" />
            <img src="assets/payments/apple-pay.svg" class="no-border" />
            <img src="assets/payments/google-pay.svg" />
            <img src="assets/payments/paypal.svg" />
            <img src="assets/payments/visa.svg" />
            <img src="assets/payments/mastercard-alt.svg" />
            <img src="assets/payments/american-express.svg" />
            <img src="assets/payments/amazon-pay.svg" class="no-border" />
          </div>
        </span>
      </div>
      <div class="home-container06">
        <div class="home-pricing-card">
          <div>
            <div class="home-container14 price_first_part">
              <span class="home-text17 heading3"
                >Full Bot<span style="font-size: 18px; font-weight: 500"> (7 days free trial) </span>
              </span>
              <span class="home-basic-plan-description"> All Features Included! </span>
            </div>
            <div class="home-container15">
              <span class="home-plan-pricing">99</span>
              <span class="home-text21">$ / source / month</span>
            </div>
          </div>
          <div class="home-container16">
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Customize your welcome messages or let AI respond. </span>
            </div>
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Customize and Schedule your follow-ups. </span>
            </div>
            <div class="home-container11">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features1"> Create and Save Your Own Quick Replies </span>
            </div>
            <div class="home-container12">
              <span class="home-checkbox">✔</span>
              <span class="home-free-plan-features2"> Leads Management System. </span>
            </div>
            <div class="home-container18">
              <span class="home-checkbox">✔</span>
              <span class="home-basic-plan-features">
                Integrating with:<br />
                HubSpot, Pipedrive, SMS, Whatsapp...
              </span>
            </div>
            <div class="home-container21">
              <span class="home-checkbox">✔</span>
              <span class="home-basic-plan-features3">Invite your team.</span>
            </div>
            <div class="home-container21">
              <span class="home-checkbox">✔</span>
              <span class="home-basic-plan-features3">More free time.</span>
            </div>
            <div class="home-container07 price_first_part">
              <span class="home-text09 heading3">Free Trial</span>
              <span class="home-free-plan-description">
                Enjoy a {{ trialDays }}-day free trial to explore all features.
              </span>
            </div>
          </div>
          <a href="/login" class="link-button">
            <button class="home-button1 button">Start for free</button>
          </a>
        </div>
        <div class="home-pricing-card">
          <div>
            <div>
              <div class="home-container22 price_first_part">
                <span class="home-text28 heading3">
                  <span>Premium</span>
                  <br />
                </span>
                <span class="home-pro-plan-description"> Become a Premium Customer </span>
              </div>
              <div class="home-container23">
                <span class="home-plan-pricing">149</span>
                <span class="home-text34">$ / source / month</span>
              </div>
            </div>
            <div class="home-container24">
              <div class="home-container25">
                <span class="home-checkbox">✔</span>
                <span class="home-text36"> All features of Essential plan</span>
              </div>
              <div class="home-container26">
                <span class="home-checkbox">✔</span>
                <span class="home-pro-plan-features">Priority support</span>
              </div>
              <div class="home-container26">
                <span class="home-checkbox">✔</span>
                <span class="home-pro-plan-features">Customer specific development</span>
              </div>

              <!--              <img src="assets/yelp-logo_w_bg.png" width="150px" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home-details anchor" id="testimonials">
    <div class="home-testimonials">
      <h3 class="home-banner-heading heading2">What Our Valued Customers Are Saying</h3>
      <app-testimonials style="display: block; width: 100%"></app-testimonials>
    </div>
  </div>

  <div class="video-container">
    <iframe
      id="video"
      width="100%"
      height="720"
      src="https://www.youtube.com/embed/a7F8LyE4pfo?si=Gf5tBkkWGlrDGdA8"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen></iframe>
  </div>

  <div class="home-banner">
    <div class="home-banner1">
      <h3 class="home-banner-heading heading2">Still have questions?</h3>
      <h3 class="home-banner-heading heading3">Contact us now for a 20 min demo and 7-day free trial</h3>
      <span class="home-banner-sub-heading">
        <div class="main-buttons">
          <div class="home-btn-group">
            <a class="home-hero-button button2" (click)="contactUs()">Contact Us</a>
          </div>
          <div class="home-btn-group">
            <a class="home-hero-button button" href="/login">Start for free</a>
          </div>
        </div>
      </span>
    </div>
  </div>

  <div class="home-footer">
    <footer class="home-footer1">
      <div class="home-separator"></div>
      <div class="home-container33">
        <div>
          <span class="home-text61"
            >© {{ year }} {{ logoService.hostTextPrimary }} Inc. All Rights Reserved.
            <a href="{{ document.location.origin }}/privacy">Privacy Policy</a>,
            <a href="{{ document.location.origin }}/tos">Term Of Service</a></span
          >
          <br />
          <div class="home-icon-group1">
            <!--          <svg viewBox="0 0 950.8571428571428 1024" class="home-icon10">-->
            <!--            <path-->
            <!--              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>-->
            <!--          </svg>-->
            <a target="_blank" href="https://instagram.com/nzleads">
              <svg viewBox="0 0 877.7142857142857 1024" class="home-icon12">
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=61560107116152">
              <svg viewBox="0 0 602.2582857142856 1024" class="home-icon14">
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
