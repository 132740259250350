<div class="flexColumnParent">
  <h1 class="flexItem" mat-dialog-title>{{ address }}</h1>
  <div mat-dialog-content class="flexItemBiggest">
    <div *ngIf="ready" style="height: 100%; width: 100%" leaflet [leafletOptions]="options"></div>
  </div>

  <div mat-dialog-actions class="flexItem">
    <button mat-button (click)="dialogRef.close()" cdkFocusInitial>close</button>
  </div>
</div>
