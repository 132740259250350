import {ObjectId} from "mongodb";
import {isEqual, sortBy} from "lodash";

export default class ArrayHelpers {
  static SortAlphabetically = (props = ["name"]) => {
    return (obj1: any, obj2: any) => {
      let i = 0,
        result = 0,
        numberOfProperties = props.length;
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    };
  };

  private static dynamicSort = (property: string) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      // next line works with strings and numbers
      const first =
        a[property] === undefined || a[property] === null ? "" : a[property];
      const second =
        b[property] === undefined || b[property] === null ? "" : b[property];
      const result = first < second ? -1 : first > second ? 1 : 0;
      return result * sortOrder;
    };
  };

  static objectIdArrEqual(array1: ObjectId[], array2: ObjectId[]) {
    const getId = (o: ObjectId) => o.toString();
    return isEqual(sortBy(array1, getId), sortBy(array2, getId));
  }
}
