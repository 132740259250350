import {Component, Inject, Input} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {DOCUMENT} from "@angular/common";
import {HostURL} from "../../../../../../server/src/db/classes/hostURLs";
import {LogoService} from "../../../Services/logo-service";

export interface URLS {
  host: HostURL;
}

@UntilDestroy()
@Component({
  selector: "dynamic-logo",
  templateUrl: "dynamic-logo.component.html",
  styleUrls: ["dynamic-logo.component.scss"]
})
export class DynamicLogoComponent {
  @Input() fontWeight = 500;

  constructor(public logoService: LogoService) {}
}
