<!--<h1 mat-dialog-title>{{data.name}}</h1>-->
<div mat-dialog-content>
  <div class="flexColumnParent">
    <div class="flexItem">
      <h3>
        Last Update Time:
        {{ data.lastRefreshTime | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat: "M/d/yyyy, hh:mm:a" }}
      </h3>
    </div>

    <div class="flexItemBiggest">
      <div>
        <mat-card *ngFor="let result of results">
          <div class="{{ map[result.status].color }}">Status: {{ map[result.status].text }}</div>
          <a href="{{ result.url }}" target="_blank">Name: {{ result.name }}</a>
          <div>Address: {{ result.display_address }}</div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="this.dialogRef.close()">Close</button>
</div>
