import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {MinimalUser} from "../Pages/superAdmin/login-as/login-as.component";
import {ClientSession} from "../../../../server/src/db/classes-helpers/clientSession";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class SuperAdminService {
  constructor(private connectionService: ConnectionService) {}

  async getAllUserWithId(): Promise<{allUsers: MinimalUser[]}> {
    return await this.connectionService.post("superAdmin/getAllUsers", {});
  }

  async loginAs(selectedUserId: string): Promise<{newSession: ClientSession}> {
    return await this.connectionService.post("superAdmin/loginAs", {selectedUserId});
  }
}
