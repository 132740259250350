import {Component, inject, NgZone} from "@angular/core";
import {LoginService} from "../../../Services/login.service";
import {Router} from "@angular/router";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {MainLoginComponent} from "../main-login.component";
import {StorageService} from "../../../Services/storage.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-sign-up",
  templateUrl: "./code-confirm.component.html",
  styleUrls: ["./code-confirm.component.scss"]
})
export class CodeConfirmComponent {
  email = "";
  code = "";
  showPassword = false;
  url;
  errors;

  constructor(
    private loginService: LoginService,
    private ngZone: NgZone,
    public mainLoginComponent: MainLoginComponent,
    private router: Router
  ) {
    this.email = StorageService.getItem("email") || "";
  }

  gotoLogin() {
    this.router.navigateByUrl("/login");
  }

  confirmCode = async () => {
    this.errors = undefined;
    try {
      this.mainLoginComponent.isAuthInProgress = true;
      StorageService.setItem("email", this.email);
      await this.loginService.signup({
        email: this.email.toLowerCase(),
        code: this.code
      });
    } catch (e) {
      if (e.parseErrors) {
        return (this.errors = e.parseErrors);
      }
      this.mainLoginComponent.loginError = e?.error?.error || e?.error || e;
    } finally {
      this.mainLoginComponent.isAuthInProgress = false;
    }
  };
}
