import {Component, Inject, Optional} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Business} from "../../../../../server/src/db/classes/business";
import {DataService} from "../../Services/data.service";
import {cloneDeep} from "lodash";
import {BusinessService} from "../../Services/business.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionService} from "../../Services/session.service";
import {StringConstants} from "../../../../../server/src/helpers/string-constants";

@UntilDestroy()
@Component({
  selector: "business-dialog",
  templateUrl: "./business-dialog.component.html",
  styleUrls: ["./business-dialog.component.scss"]
})
export class BusinessDialogComponent {
  allBusinessesFlatById: {[key: string]: Business | any} = {};
  allBusinessesFlat = [];
  saving = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<BusinessDialogComponent>,
    private businessService: BusinessService,
    private _snackBar: MatSnackBar,
    public sessionService: SessionService,
    private dataService: DataService
  ) {
    this.data.business = cloneDeep(this.data.business);
    if (!data.business.parent) {
      data.business.parent = StringConstants.AllBusinessesId;
    }
    dataService.allBusinessesHashedById$
      .pipe(untilDestroyed(this))
      .subscribe(allBusinessesFlatById => {
        this.allBusinessesFlatById = allBusinessesFlatById;
        this.allBusinessesFlat = Object.values(this.allBusinessesFlatById);
      });
    console.log(data, dataService);
  }

  async save() {
    this.saving = true;
    try {
      await this.businessService.update(this.data.business);
    } catch (e) {
      this._snackBar.open(e, undefined, {
        duration: 3000
      });
      this.saving = false;
      return;
    }
    await this.dataService.updateBusinessAndSources();
    this.dialogRef.close();
  }
}
