import {Component, Input} from "@angular/core";
import {OperationConfigBaseComponent} from "../operation-config-base.component";
import {YelpConfigNotifyCustomer} from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import {OperationConfigComponent} from "../operation-config.component";

@Component({
  selector: "app-operation-config-customer-phone-found",
  templateUrl: "./operation-config-customer-phone-found.component.html",
  styleUrl: "./operation-config-customer-phone-found.component.scss"
})
export class OperationConfigCustomerPhoneFoundComponent extends OperationConfigBaseComponent<YelpConfigNotifyCustomer> {
  @Input() override inputConfig: YelpConfigNotifyCustomer | null;
  @Input() override inputGlobalConfig: YelpConfigNotifyCustomer;

  constructor(public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  protected override notifyConfigChange(): void {
    this.operationConfigComponent.customerNotifyConfigChaged(this.config, this.isGlobalConfigApplied);
  }
}
