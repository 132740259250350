import {NotificationIntegrations} from "../classes/notification-integration.type";

export type TimePeriod = {
  start: string;
  end: string;
};

export type DailySchedule = TimePeriod[];

export type WeekDays = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "AnyDay";

export type WeekDaySchedule = {
  [day in WeekDays]?: DailySchedule;
};

export interface YelpConfigWelcomePredefinedMessages {
  text: string;
  preventFollowUps: boolean;
  words?: string[];
}

export interface YelpConfigWelcomeMessage {
  predefinedMessages: YelpConfigWelcomePredefinedMessages[];
  isAiEnabled?: boolean;
  aiConfig?: WelcomeMessageAiConfig;
}

export enum WelcomeMessageAiConfigAnswerSize  {
  Short = 30,
  Long = 50,
  Detailed = 80,
}

export interface WelcomeMessageAiConfig {
  businessDescription: string;
  canDo: string;
  cantDo: string;
  answerSize?: WelcomeMessageAiConfigAnswerSize;
}

export enum YelpConfigFollowUpPredefinedMessagesType {
  Minutes = "Minutes",
  Hours = "Hours",
  Days = "Days"
}

export interface YelpConfigFollowUpPredefinedMessages {
  text: string;
  minutes: number;
  type?: YelpConfigFollowUpPredefinedMessagesType;
  displayValue?: number;
}

export interface YelpConfigFollowUp {
  predefinedMessages: YelpConfigFollowUpPredefinedMessages[];
}

export type YelpConfigMessage = YelpConfigWelcomeMessage | YelpConfigFollowUp;
export type YelpConfigMessages = YelpConfigMessage[];
export type YelpConfigNotifyCustomer = {
  send: boolean;
  sendOnLaterMessages: boolean;
  text: string;
  textBusinessOffHours?: string;
};
export type YelpConfigNotifyBusiness = {
  sendWhatsapp: boolean;
  addBusinessNameToMessage: boolean;
};

export interface YelpConfig {
  welcomeMessage?: YelpConfigWelcomeMessage;
  followUps?: YelpConfigFollowUp;
  sendCustomerMessageOnPhoneFound?: YelpConfigNotifyCustomer;
  botOffHours?: WeekDaySchedule;
  noFollowUpHours?: WeekDaySchedule;
  timeZone?: string;
  notificationIntegrations?: NotificationIntegrations;
}
