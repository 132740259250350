import {SocialUser} from "@abacritt/angularx-social-login";
import {Component, NgZone, OnInit} from "@angular/core";
import {LoginService} from "src/app/Services/login.service";

declare const google: any;

@Component({
  selector: "app-google-popup-login",
  templateUrl: "./google-popup-login.component.html",
  styleUrl: "./google-popup-login.component.scss"
})
export class GooglePopupLoginComponent implements OnInit {
  user: any;

  constructor(
    private loginService: LoginService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.loadGoogleScript();
  }

  loadGoogleScript() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      this.initializeGoogleSignIn();
    };
  }

  initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: "70803016125-4sovfr8kccklv6b7boha1urgap58d3cn",
      callback: this.handleCredentialResponse.bind(this),
      use_fedcm_for_prompt: true
    });
    google.accounts.id.renderButton(document.getElementById("g_id_signin"), {theme: "outline", size: "large"});
    google.accounts.id.prompt();
  }

  handleCredentialResponse(response: any) {
    this.ngZone.run(async () => {
      const token = response.credential;

      const socialUser = {
        idToken: token
      } as unknown as SocialUser;

      await this.loginService.loginWithGoogle(socialUser);
    });
  }
}
