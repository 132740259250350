import {AfterViewInit, Component, Input} from "@angular/core";
import {NotificationIntegrationFactory} from "../../../../../../server/src/db/classes/notification-integration.factory";
import {
  NotificationIntegration,
  NotificationIntegrationBase,
  NotificationIntegrationDefault,
  NotificationIntegrationDefaultParams,
  NotificationIntegrationType,
  NotificationIntegrations
} from "../../../../../../server/src/db/classes/notification-integration.type";
import {OperationConfigBaseComponent} from "../operation-config-base.component";
import {OperationConfigComponent} from "../operation-config.component";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";

@Component({
  selector: "app-integrations",
  templateUrl: "./integrations.component.html",
  styleUrl: "./integrations.component.scss"
})
export class IntegrationsComponent
  extends OperationConfigBaseComponent<NotificationIntegrations>
  implements AfterViewInit
{
  @Input() override inputConfig: NotificationIntegrations | null;
  @Input() override inputGlobalConfig: NotificationIntegrations;
  @Input() sourceConfig: SourceConfigDialogData;
  public selectedIntegration: NotificationIntegrationType | null = null;
  public notificationIntegrationType = NotificationIntegrationType;

  constructor(public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  ngAfterViewInit(): void {
    this.initialize();
  }

  private initialize(): void {
    if (this.config) {
      this.config = this.mergedConfig();
      this.sortIntegrations();
    }
  }

  private sortIntegrations(): void {
    if (this.config) {
      this.config.sort((a, b) => {
        // Sort by enabled status first (enabled integrations come first)
        if (a.enabled !== b.enabled) {
          return a.enabled ? -1 : 1;
        }
        // If enabled status is the same, sort by name
        return a.type.localeCompare(b.type);
      });
    }
  }

  private get baseIntegrations(): NotificationIntegrations {
    const factory = new NotificationIntegrationFactory();
    return factory.baseIntegrations;
  }

  protected override notifyConfigChange(): void {
    this.sortIntegrations(); // Apply sorting after config changes
    this.operationConfigComponent.notificationIntegrationsNotifyConfigChaged(this.config, this.isGlobalConfigApplied);
  }

  public getDefaults(integration: NotificationIntegration): NotificationIntegrationDefaultParams {
    return new NotificationIntegrationDefault(integration);
  }

  public selectIntegration(type: NotificationIntegrationType | null) {
    this.selectedIntegration = this.selectedIntegration === type ? null : type;
    this.sortIntegrations();

    // If we're deselecting an integration (type is null), also apply sorting
    // This covers the case when toggling global settings
    if (type === null) {
      setTimeout(() => this.sortIntegrations(), 0);
    }
  }

  private mergedConfig() {
    return [...this.config, ...this.baseIntegrations].reduce((acc, current) => {
      const x = acc.find(item => item.type === current.type);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  public getIntegration<T extends NotificationIntegration>(
    integration: NotificationIntegration,
    type: NotificationIntegrationType
  ): T {
    const defaultValues: NotificationIntegrationBase = {enabled: false, type};
    return integration && integration.type === type ? (integration as T) : (defaultValues as T);
  }
}
