import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {Dialog} from "@angular/cdk/dialog";

export const ModalGuard: CanActivateFn = (route, state) => {
  const dialog = inject(Dialog);
  if (dialog.openDialogs.length === 0) {
    return true;
  }
  dialog.openDialogs.forEach(dialog => dialog.close());
  history.forward();
  return false;
};
