import {Pipe, PipeTransform} from "@angular/core";
import {PhoneNumberUtil, PhoneNumberFormat} from "google-libphonenumber";

@Pipe({standalone: true, name: "phonePipe"})
export class PhonePipe implements PipeTransform {
  transform<T = any>(phoneNumber: any): T {
    // Initialize the phone number util
    const phoneUtil = PhoneNumberUtil.getInstance();

    // Example phone number

    // Parse the phone number
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");

    // Format the number in the desired format, e.g., NATIONAL
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL); //(818) 111-2233
  }
}
