<ng-container *ngIf="data">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <div mat-dialog-content style="white-space: pre-wrap; padding: 24px !important;">
    <div>{{ data.mainText }}</div>
    <br />
    <div style="font-size: 0.8rem">{{ data?.comment }}</div>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()" *ngIf="!data.hideCancel">
      {{ data.CancelText || "Cancel" }}
    </button>

    <button
      mat-button
      mat-raised-button
      cdkFocusInitial
      color="primary"
      (click)="dialogRef.close(true)"
      *ngIf="!data.hideConfirm">
      {{ data.action || "Ok" }}
    </button>
  </div>
</ng-container>
