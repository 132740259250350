import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {SessionService} from "./session.service";

@Injectable({
  providedIn: "root"
})
export class YelpReportService {
  constructor(
    private connectionService: ConnectionService,
    private sessionService: SessionService
  ) {}

  async get(range, locationIds) {
    try {
      return await this.connectionService.post("yelpReport/get", {
        range,
        locationIds
      });
    } catch (e) {
      return [];
    }
  }
}
