<h1 mat-dialog-title>{{ data.name }} Settings</h1>
<div mat-dialog-content class="content-container">
  <div class="form-container">
    <app-operation-config
      [saveButton]="false"
      [config]="data"
      #appConfig></app-operation-config>
  </div>
</div>
<div mat-dialog-actions *ngIf="appConfig.ready">
  <button mat-button (click)="dialogRef.close()" cdkFocusInitial>Cancel</button>
  <button
    mat-button
    mat-raised-button
    color="primary"
    (click)="saveSettings()"
    cdkFocusInitial>
    Save
  </button>
</div>
