<div class="follow-up-config-container">
  <div class="card-actions">
    <div class="intro">
      <h3>AI-Powered Welcome Messages!</h3>
      <p>
        Enhance your welcome messages with AI. Customize your responses by providing examples of how you want the AI to
        reply. You can also include pricing for each item if you want quotes to be given.
      </p>
    </div>
    <mat-form-field floatLabel="always">
      <mat-label>Answer Size</mat-label>
      <mat-select
        [disabled]="isGlobalConfigApplied"
        [(ngModel)]="inputConfig.answerSize"
        (selectionChange)="onAnswerSizeChange($event)">
        <mat-option *ngFor="let option of answerSizeOptions" [value]="option.value">
          {{option.name}} ({{option.value}} words)
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Welcome message Instructions:</mat-label>
      <textarea
        [disabled]="isGlobalConfigApplied"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        [maxlength]="maxLenBusinessDescription"
        placeholder="Example: With 20 years in construction, we use top-quality materials and workmanship to achieve perfect results. Always respond politely, beginning with 'Hey' followed by the customer's name. Conclude each message with: 'Best regards, Construction Team, (213) 111-1111.'"
        [(ngModel)]="inputConfig.businessDescription">
      </textarea>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Services not Providing (prevents follow-ups)</mat-label>
      <textarea
        [disabled]="isGlobalConfigApplied"
        [maxlength]="maxLenCanDo"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        placeholder="Example: We do not take small jobs under $500, including minor repairs like fixing small areas of broken tiles (less than 10 sq ft), repairing leaky faucets, patching drywall holes, or installing light fixtures and similar minor tasks. For larger jobs or if unsure, mention the minimum and request a phone number to discuss further."
        [(ngModel)]="inputConfig.cantDo"></textarea>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Services Providing (if want to include pricing)</mat-label>
      <textarea
        [disabled]="isGlobalConfigApplied"
        [maxlength]="maxLenCanDo"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        placeholder="Example: Minimum Project Fee: $500, Window Installation: $200 per window, Complete Home Construction: Subject to plan review, Comprehensive Home Renovations: Starting at $50 per square foot, ADU Garage Conversions: Evaluation required prior to quotation, Roof Installation: $4 to $20 per square foot, depending on materials."
        [(ngModel)]="inputConfig.canDo"></textarea>
    </mat-form-field>
  </div>
</div>
