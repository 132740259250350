<!--<div class="main-content">-->
<!--  <h2>What to do when a customer sends a phone number?</h2>-->
<!--</div>-->

<div class="main-content">
  <h4>When a customer sends a phone number:</h4>
  <mat-checkbox [checked]="!inputConfig" (click)="toggleGlobalSettings()">
    {{ operationConfigComponent.globalConfigText }}
  </mat-checkbox>

  <div>
    <mat-slide-toggle
      [ngModel]="config?.send"
      (ngModelChange)="config.send = $event; config.sendOnLaterMessages = $event"
      [disabled]="isGlobalConfigApplied"
      color="primary">
      Send message to customer
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle
      [ngModel]="config?.sendOnLaterMessages"
      (ngModelChange)="config.sendOnLaterMessages = $event"
      [disabled]="isGlobalConfigApplied || !config.send"
      color="primary">
      Send Even if the phone number was sent by the customer not on the first message.
    </mat-slide-toggle>
  </div>

  <h4>Message</h4>

  <mat-form-field>
    <mat-label> Reply with a message after customer sent a phone number</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      [ngModel]="config?.text"
      (ngModelChange)="config?.text !== undefined && (config.text = $event)"
      [disabled]="isGlobalConfigApplied || !config.send"></textarea>
  </mat-form-field>

  <h4>Message to send during business off hours: (Configured on Yelp)</h4>

  <mat-form-field>
    <mat-label> Reply with a message after customer sent a phone number</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      [ngModel]="config?.textBusinessOffHours"
      (ngModelChange)="config.textBusinessOffHours = $event"
      [disabled]="isGlobalConfigApplied || !config.send"></textarea>
  </mat-form-field>
</div>
