import {CanActivateFn, Router} from "@angular/router";
import {inject, NgZone} from "@angular/core";
import {SessionService} from "../Services/session.service";

export const LoginGuard: CanActivateFn = (route, state) => {
  const sessionService = inject(SessionService);
  if (!sessionService.session) {
    return true;
  }

  const router = inject(Router);
  router.navigateByUrl("/app/leads");
  return false;
};
