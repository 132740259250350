import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {User, UserSetting} from "../../../../server/src/db/classes/user";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private connectionService: ConnectionService) {}

  public async getUserSettings(): Promise<UserSetting | null> {
    return await this.connectionService.post("user/settings");
  }

  public async updateUserSettings(predefinedMessages: {title?: string; text: string}[]): Promise<User> {
    return await this.connectionService.post("user/updateSettings", {predefinedMessages});
  }
}
