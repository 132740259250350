import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LatLng, marker, tileLayer} from "leaflet";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "map-messages-dialog",
  templateUrl: "./map-dialog.component.html",
  styleUrls: ["./map-dialog.component.scss"]
})
export class MapDialogComponent {
  options: any = {
    layers: [
      tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18
      })
    ],
    zoom: 13
  };
  ready = false;
  address = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<MapDialogComponent>
  ) {
    this.getAddress(data.lead.address + " usa");
  }

  getAddress = async address => {
    // Using Nominatim for geocoding
    const nominatimEndpoint = "https://nominatim.openstreetmap.org/search";
    const params = {
      q: address,
      format: "json"
    };

    const url = `${nominatimEndpoint}?${new URLSearchParams(params)}`;

    const res = await fetch(url);
    const data = await res.json();

    if (data.length > 0) {
      const lat = parseFloat(data[0].lat);
      const lng = parseFloat(data[0].lon);

      // Center the map to the geocoded location and add a marker
      this.options.center = new LatLng(lat, lng);
      this.options.layers.push(marker([lat, lng]));
      this.address = data[0].display_name;

      this.ready = true;
    } else {
      console.error("Address not found.");
    }
  };
}
