<h1 class="header">Confirm Code</h1>
<form class="form">
  <div class="content">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="email" disabled name="email" />
      <mat-hint *ngIf="errors?.email">{{ errors.email }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Code received by Email</mat-label>
      <input autoFocus name="code" matInput [(ngModel)]="code" />
      <mat-hint *ngIf="errors?.code">{{ errors.code }}</mat-hint>
    </mat-form-field>
  </div>

  <button type="submit" mat-button mat-raised-button color="primary" class="login-button" (click)="this.confirmCode()">
    Confirm Code
  </button>
  <button type="submit" mat-button mat-raised-button class="login-button" (click)="this.gotoLogin()">
    Back to Login
  </button>
</form>
