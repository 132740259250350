import {Component, Input} from "@angular/core";
import { NzDialogService } from "src/app/utils/services/nz-dialog.service";
import { ImageDialogComponent } from "./image-dialog.component";

@Component({
  selector: "feature-card",
  templateUrl: "feature-card.component.html",
  styleUrls: ["feature-card.component.scss"]
})
export class FeatureCard {
  @Input()
  Heading: string = "Lorem ipsum";

  @Input()
  width = "500";

  @Input()
  Image: string;

  @Input()
  OriginalImage: string;

  @Input()
  paddingTopImage = "0";

  @Input()
  SubHeading: string =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.";

  constructor(
    private nzDialogService: NzDialogService
  ) {}



  public openOriginialImage(): void {
    this.nzDialogService.open(ImageDialogComponent, {
      width: "90vw",
      height: "90vh",
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: { imageSrc: this.OriginalImage }
    });
  }
}
