import {DateTime, Interval, Settings} from "luxon";
import {YelpLocationInfo} from "../db/classes-helpers/yelpLocationInfo";

export const ptZone = "America/Los_Angeles";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export enum JsWeekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum YelpWeekday {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6
}

export function getIntervalBetweenTwoDates(a: DateTime, b: DateTime): Interval {
  return Interval.fromDateTimes(
    DateTime.fromMillis(Math.min(a.toMillis(), b.toMillis())),
    DateTime.fromMillis(Math.max(a.toMillis(), b.toMillis()))
  );
}

export function CalculateActiveTime(activities, periodStart, periodEnd) {
  let ActualActiveTime = 0;
  const PeriodTime = periodEnd - periodStart;

  activities.forEach(activity => {
    const startTime = new Date(activity.start);
    const endTime = activity.end === null ? new Date() : new Date(activity.end);

    const activityStart = startTime.getTime();
    const activityEnd = endTime.getTime();

    const activeStartTime = Math.max(activityStart, periodStart);
    const activeEndTime = Math.min(activityEnd, periodEnd);
    const timeDiff = activeEndTime - activeStartTime;
    if (timeDiff > 0) {
      ActualActiveTime += timeDiff;
    }
  });

  // Convert milliseconds to minutes
  return {
    mili: ActualActiveTime,
    percent: ActualActiveTime / PeriodTime
  };
}

export function isWithinWorkingHours(yelpLocationInfo: YelpLocationInfo, timeZone: string): boolean {
  try {
    const now = DateTime.now().setZone(timeZone);
    const currentDay = (now.weekday + 6) % 7; // Convert Luxon's 1-7 to Yelp's 0-6
    const currentTime = now.hour * 100 + now.minute;

    if (!yelpLocationInfo.hours?.[0]?.open) {
      console.warn("No valid business hours provided in YelpLocationInfo");
      return false;
    }

    const businessHours = yelpLocationInfo.hours[0].open;
    const todayHours = businessHours.filter(hour => hour.day === currentDay);
    const yesterdayHours = businessHours.filter(hour => hour.day === (currentDay - 1 + 7) % 7);

    // Check today's hours
    for (const hours of todayHours) {
      const start = parseInt(hours.start);
      const end = parseInt(hours.end);

      if (hours.is_overnight) {
        if (currentTime >= start) {
          return true;
        }
      } else {
        if (currentTime >= start && currentTime < end) {
          return true;
        }
      }
    }

    // Check yesterday's overnight hours
    for (const hours of yesterdayHours) {
      if (hours.is_overnight) {
        const end = parseInt(hours.end);
        if (currentTime < end) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error("Unexpected error in isWithinWorkingHours:", error);
    return false;
  }
}
